var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'UserLoginCodeInfo',"size":"xl","headerText":_vm.$t('UserLoginCodes.data'),"headerIcon":_vm.userLoginCode.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.userLoginCode.fullCode,"title":_vm.$t('UserLoginCodes.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.userLoginCode.userLoginCodeNameAr,"title":_vm.$t('UserLoginCodes.nameAr'),"imgName":'UserLoginCodes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.userLoginCode.userLoginCodeNameEn,"title":_vm.$t('UserLoginCodes.nameEn'),"imgName":'UserLoginCodes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.userLoginCode.userLoginCodeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.userLoginCode.loginCode,"title":_vm.$t('UserLoginCodes.loginCode'),"imgName":'UserLoginCodes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.userLoginCode.userLoginCodePlatFromNameCurrent,"title":_vm.$t('PlatFroms.modelName'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.formateDateTimeLang(
          _vm.userLoginCode.codeActiveFromDate,
          _vm.userLoginCode.codeActiveFromTime
        ),"title":_vm.$t('UserLoginCodes.codeIsActive') +
        ' ' +
        _vm.$t('UserLoginCodes.codeIsActiveFrom'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.formateDateTimeLang(
          _vm.userLoginCode.codeActiveToDate,
          _vm.userLoginCode.codeActiveToTime
        ),"title":_vm.$t('UserLoginCodes.codeIsActive') +
        ' ' +
        _vm.$t('UserLoginCodes.codeIsActiveTo'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.userLoginCode.codeActiveDurationCurrent,"title":_vm.$t('UserLoginCodes.codeIsActiveDuration'),"imgName":'dateAndTime.svg'}}),_c('CustomCheckbox',{attrs:{"className":'col-md-4',"value":_vm.userLoginCode.codeIsActiveUnTileUserSignOut,"title":_vm.$t('UserLoginCodes.codeIsActiveUnTileUserSignOut'),"showAsInfo":true}}),_c('CustomCheckbox',{attrs:{"className":'col-md-4',"value":_vm.userLoginCode.loginCodeIsUsedStatus,"title":((_vm.$t('UserLoginCodes.loginCodeStatus')) + " [" + (_vm.$t(
        'UserLoginCodes.loginCodeStatusUsed'
      )) + "]"),"showAsInfo":true}}),_c('CustomCheckbox',{attrs:{"className":'col-md-4',"value":_vm.userLoginCode.loginCodeIsExpiredStatus,"title":((_vm.$t('UserLoginCodes.loginCodeStatus')) + " [" + (_vm.$t(
        'UserLoginCodes.loginCodeStatusExpired'
      )) + "]"),"showAsInfo":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }